
/* .headerLogo {
	position: fixed;
	top: 0px;
	left: 7.4%;
	z-index: 999;
	width: 170px;
	height: 80px;
	display: flex;
	align-items: center;
} */

/* .headerMenu {
	position: fixed;
	top: 0px;
	right: 7.4%;
	z-index: 999;
	height: 80px;
	display: flex;
	align-items: center;
	cursor: pointer;
} */

.headerMenu:focus {
	outline: none;
}

.headerLogo {
	margin-bottom: 0;
}

@media (max-width: 575.9px) {
	.headerLogo {
		width: 130px;
	}
}

@media (max-width: 767px) {
	.headerLogo {
		width: 150px;
	}

	.headerMenu > img {
		width: 25px;
	}
}