.react-datepicker-wrapper,
.input-time input,
.custom-form .input-bill {
    width: 300px;
}
.date-picker {
    position: relative;
}
.date-picker .btn-datepicker {
    top: 4px;
    right: 10px;
    position: absolute;
    background-color: transparent;
}
.date-picker .btn-datepicker img {
    max-width: 15px;
}

@media (max-width: 320px) {
    .react-datepicker-wrapper,
    .input-time input,
    .custom-form .input-bill {
        width: 260px;
    }
}

/* section article input {
    font-weight: bold !important;
} */

section article input::placeholder {
    color: #ffffff !important;
}

section h2, section article footer > :first-child {
    color: #55b67a !important;
}

section article footer > :last-child {
    background-color: #55b67a !important;
    border: 1px solid #55b67a;
    color: rgba(255,255,255,1);
}

section article div[role="presentation"] {
    background-color: #55b67a !important;
}

section article input {
    color: #ffffff !important;
}

section article span {
    color: #ffffff !important;
}

div[class^="sc-"]::after {
    color: #55b67a !important;
}

section button[data-id="button-icon-element"] {
    color: #55b67a !important;
}

section button[data-selected="true"] {
    background-color: #55b67a !important;
}

section button[data-id="button-icon-element"] svg {
    color: #55b67a !important;
}

div[role="presentation"] {
    overflow-y: auto;
}