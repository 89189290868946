
@font-face {
    font-family: 'Helvetica';
    src: url('./HelveticaRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica';
    src: url('./HelveticaMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica';
    src: url('./Helvetica\ 95\ Black.otf') format('otf');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Arial';
    src: url('./ArialRegular.TTF') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Arial';
    src: url('./ArialMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Arial';
    src: url('./ArialBold.TTF') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Arial';
    src: url('./ArialBlack.TTF') format('truetype');
    font-weight: 900;
    font-style: normal;
}




