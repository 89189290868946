.file-input {
	right: 0;
	bottom: 0;
	opacity: 0;
	z-index: 2;
	width: 100px;
	height: 100px;
	cursor: pointer;
	color: transparent;
	position: absolute;
}

.pro-img.profile-image-present .file-input {
	border-radius: 50%;
}

.pro-img.profile-image-present .badge {
	display: none;
}

.pro-img {
	position: relative;
}

.pro-img figure {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 50%;
}

.pro-img .menu-profile-image img[src="/images/menu-2.png"] {
	filter: invert(93%) sepia(89%) saturate(2%) hue-rotate(299deg) brightness(105%) contrast(101%);
}

.pro-img img[src='/images/menu-2.png'] {
	top: 50%;
	left: 50%;
	width: auto;
	height: auto;
	max-width: 50px;
	position: absolute;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.file-input::-webkit-file-upload-button {
	visibility: hidden;
}
.file-input::before {
	content: '-';
	color: black;
	cursor: pointer;
	font-size: 10pt;
}

.map-sec figure > div {
	/* height: auto !important; */
	position: unset !important;
}

.social-links {
	position: absolute;
	right: 0;
}

.pro-img {
	width: 100px;
	height: 100px;
}

.pro-img .loader {
	width: 100%;
	height: 100%;
}

.pro-img .remove-btn {
	top: 0;
	right: 0;
	padding: 0;
	width: 20px;
	height: 20px;
	cursor: pointer;
	position: absolute;
	border-radius: 50%;
}

.pro-img .remove-btn .remove-img {
	width: 100%;
	height: 100%;
	display: block;
}

.bio-wrap .pro-img figure:after {
	content: 'x';
	top: 0;
	cursor: pointer;
	display: none;
}

.pro-img .badge {
    top: 0;
	right: 0;
    width: 20px;
    height: 20px;
	color: #fff;
	position: absolute;
    border-radius: 50%;
    background-color: #e29030;
}

.pro-img .badge .fa-plus {
	font-size: 10px;
}

.banner-content .content-wrap {
	width: 100%;
}

.bio-wrap .content-wrap p {
	padding: 10px;
	overflow: auto;
	min-height: 150px;
	max-height: 150px;
	border-radius: 4px;
	word-break: break-all;
	border: 2px solid #ffffff;
}

.profile-bio-p {
	width: 100%;
	padding: 10px;
	overflow: auto;
	text-align: left;
	min-height: 150px;
	max-height: 150px;
	border-radius: 4px;
	white-space: pre-line;
	word-break: break-word;
	border: 2px solid #ffffff;
}

.banner-sec footer {
	background: #ffffff;
}

.banner-sec footer .container-wrap {
	display: block;
}

.save-btn {
	color: #ffffff;
	font-size: 14px;
	padding: 4px 12px;
	border-radius: 6px;
	margin: 0 !important;
	background-color: #35625d;
	box-shadow: -4px 4px 3px 0 #3a3a3a;
}
.button {
	font-size: 14px;
	padding: 4px 12px;
	border-radius: 6px;
	margin: 0 !important;
	box-shadow: -4px 4px 3px 0 #3a3a3a;
}

.button:hover,
.button:active {
	transform: translateY(3px);
	box-shadow: -2px 2px 3px 0 #3a3a3a;
}

.save-btn {
	color: #ffffff;
	background-color: #35625d;
	margin-left: 1em !important;
}
.save-btn:hover,
.save-btn:active {
	box-shadow: -2px 2px 3px 0 #3a3a3a;
	transform: translateY(3px);
}
.cancel-btn {
	color: #ffffff;
	font-size: 14px;
	padding: 4px 12px;
	border-radius: 6px;
	margin: 0 !important;
	background-color: #f00707;
	box-shadow: -4px 4px 3px 0 #dc3545;
	margin-right: 15px !important;
}

.cancel-btn:hover,
.cancel-btn:active {
	box-shadow: -2px 2px 3px 0 #dc3545;
	transform: translateY(3px);
}

.textarea-counter {
	right: 10px;
	bottom: 2px;
	color: #808080;
	font-size: 13px;
	position: absolute;
	background-color: #ffffff;
}

#section-6 .arrow-up {
	transform: rotate(180deg);
}

.bio-wrap .pro-img .hover-icon {
	top: 50%;
	left: 50%;
	opacity: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	position: absolute;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.4);
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
}

.bio-wrap .pro-img:hover .hover-icon {
	opacity: 1;
}

.bio-wrap .pro-img .hover-icon img {
	top: 50%;
	left: 50%;
	width: 30px;
	height: 30px;
	filter: invert(1);
	position: absolute;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
}


/*
modal-dialog-design is className which creates UI structure of modal to resemble what client expects.
Tampering with it may change the expectation. /public/css/bio.css
contains code which may tamper this dont uncomment below line
*/

/* .modal.show .modal-dialog.modal-dialog-design {
	top: 50%;
	left: 50%;
	margin: 0;
	width: 100%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
} */

@media (max-height: 767.9px) {
	/* .banner-sec {
		height: auto;
	}
	.profile-wrap {
		padding-top: 0;
	} */
}

@media (max-width: 1400px) {
	.content-wrap p {
		font-size: 20px;
		margin-bottom: 32px;
	}
	.profile-bio-p {
		margin: 0 auto;
		max-width: 800px;
	}
}

@media (max-width: 1199px) {
	.profile-bio-p {
		max-width: 600px;
	}
}

@media (max-width: 1024px) {
	/* .detail {
		margin: 0;
		width: 100%;
		border-radius: 350px;
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		padding: 50px;
		margin-bottom: -40px;
	  } */
}

@media (max-width: 991px) {
	.profile-bio-p {
		max-width: 588px;
	}
}

@media (max-width: 767.9px) {
	.profile-bio-p {
		max-width: 600px;
	}
	#section-2 {
		background-position: 25%;
	}
	.detail {
		padding-top: 180px;
	}
}

@media (max-width: 575.9px) {
	.detail {
		margin-bottom: 0;
	}
}

/* Portrait */
@media screen 
  and (device-width: 1024px) 
  and (device-height: 1366px) 
  and (orientation: portrait) {
	#section-2 {
		min-height: auto;
		background-size: 120%;
		background-position: 5% 0px;
	}
	#section-2 .detail-wrap {
		min-height: auto;
	}
	.detail {
		height: auto;
		margin-top: 430px;
		padding: 130px 250px;
		margin-bottom: -60px;
		border-radius: 500px;
	}
}

/* Landscape */
@media screen 
  and (device-width: 1024px) 
  and (device-height: 768px) 
  and (orientation: landscape) {
	#section-2 {
		background-size: 115%;
		background-position: top;
	}
	/* .detail {
		height: auto;
		margin-top: 350px;
		border-radius: 500px;
		padding: 120px 250px;
	} */
}

/* Portrait */
@media screen 
  and (device-width: 768px) 
  and (device-height: 1024px) 
  and (orientation: portrait) {
	#section-2 {
		min-height: auto;
		background-size: 120%;
		background-position: 5% 0px;
	}
	#section-2 .detail-wrap {
		min-height: auto;
	}
	/* .detail {
		height: auto;
		padding: 130px;
		margin-top: 320px;
		margin-bottom: -60px;
		border-radius: 450px;
	} */
}

/* Landscape */
@media screen 
  and (device-width: 960px) 
  and (device-height: 600px) 
  and (orientation: landscape) {
	#section-2 {
		background-size: 115%;
		background-position: top;
	}
	.detail {
		height: auto;
		margin-top: 350px;
		border-radius: 450px;
		padding: 130px 200px 110px;
	}
}

/* Portrait */
@media screen 
  and (device-width: 600px) 
  and (device-height: 960px) 
  and (orientation: portrait) {
	#section-2 {
		min-height: auto;
		background-size: 130%;
		background-position: 5% 0px;
	}
	#section-2 .detail-wrap {
		min-height: auto;
	}
	.detail {
		height: auto;
		margin-top: 280px;
		padding: 130px 100px 100px;
	}
}

/* Landscape */
@media screen 
  and (max-width: 720px) 
  and (max-height: 540px) 
  and (orientation: landscape) {
	#section-2 {
		background-size: 115%;
		background-position: top;
	}
	.detail {
		height: auto;
		margin-top: 250px;
		padding: 130px 130px 70px;
	}
}

/* Portrait */
@media screen 
  and (max-width: 540px) 
  and (max-height: 720px) 
  and (orientation: portrait) {
	#section-2 {
		background-size: 125%;
		background-position: 5% 0px;
	}
	.detail {
		height: auto;
		margin-top: 200px;
		padding: 140px 80px 60px;
	}
}

/* Landscape */
@media screen 
  and (device-width: 736px) 
  and (device-height: 414px) 
  and (orientation: landscape) {
	#section-2 {
		background-size: 115%;
		background-position: top;
	}
	.detail {
		height: auto;
		margin-top: 250px;
		padding: 130px 130px 70px;
	}
}

/* Portrait */
@media screen 
  and (device-width: 414px) 
  and (device-height: 736px) 
  and (orientation: portrait) {
	#section-2 {
		background-size: 150%;
		background-position: 5% 0px;
	}
	.detail {
		height: auto;
		margin-top: 100px;
		padding-top: 130px;
	}
}

/* Landscape */
@media screen 
  and (device-width: 823px) 
  and (device-height: 411px) 
  and (orientation: Landscape) {
	#section-2 {
		background-size: 130%;
		background-position: 10% 0px;
	}
	.detail {
		height: auto;
		margin-top: 380px;
		padding: 130px 150px;
		border-radius: 450px;
	}
}


/* Portrait */
@media screen 
  and (device-width: 414px) 
  and (device-height: 736px) 
  and (orientation: portrait) {
	#section-2 {
		background-size: 150%;
		background-position: 5% 0px;
	}
	.detail-wrap {
		min-height: auto;
	}
	.detail {
		height: auto;
		margin-top: 230px;
		padding-top: 130px;
		border-radius: 200px;
	}
}

/* Landscape */
@media screen 
  and (device-width: 732px) 
  and (device-height: 412px) 
  and (orientation: landscape) {
	#section-2 {
		background-size: 115%;
		background-position: top;
	}
	.detail {
		height: auto;
		margin-top: 250px;
		padding: 130px 130px 90px;
	}
}

/* Portrait */
@media screen 
  and (device-width: 412px) 
  and (device-height: 732px) 
  and (orientation: portrait) {
	#section-2 {
		background-size: 150%;
		background-position: 5% 0px;
	}
	.detail {
		height: auto;
		margin-top: 100px;
		padding-top: 130px;
	}
}

/* Landscape */
@media screen 
  and (device-width: 731px) 
  and (device-height: 411px) 
  and (orientation: landscape) {
	#section-2 {
		background-size: 115%;
		background-position: top;
	}
	.detail {
		height: auto;
		margin-top: 250px;
		padding: 130px 130px 70px;
	}
}

/* Portrait */
@media screen 
  and (device-width: 411px) 
  and (device-height: 731px) 
  and (orientation: portrait) {
	#section-2 {
		background-size: 150%;
		background-position: 5% 0px;
	}
	.detail {
		height: auto;
		margin-top: 100px;
		padding-top: 130px;
	}
}

@media (max-width: 360px) {
	.detail {
		margin-top: 160px;
		padding: 100px 60px 60px;
	}
}

@media (max-height: 360px) {
	#section-2 {
		background-size: contain;
		background-position: top;
	}
	.detail {
		padding: 100px 150px;
		margin-top: 200px;
	}
}


/* Portrait */
@media screen 
  and (device-width: 800px) 
  and (device-height: 1280px) 
  and (orientation: portrait) {
	#section-2 {
		min-height: auto;
		background-size: 135%;
		background-position: 10% 0px;
	}
	#section-2 .detail-wrap {
		min-height: auto;
	}
	.detail {
		height: auto;
		margin-top: 380px;
		padding: 130px 160px;
		border-radius: 400px;
	}
}

/* Landscape */
@media screen 
  and (device-width: 812px) 
  and (device-height: 375px) 
  and (orientation: landscape) {
	#section-2 {
		background-size: 125%;
		background-position: 10% 0px;
	}
	.detail {
		height: auto;
		padding: 150px;
		margin-top: 350px;
		margin-bottom: -80px;
		border-radius: 450px;
	}
}

/* Landscape */
@media screen 
  and (device-width: 667px) 
  and (device-height: 375px) 
  and (orientation: landscape) {
	#section-2 {
		background-size: 125%;
		background-position: 10% 0px;
	}
	.detail {
		height: auto;
		margin-top: 280px;
		padding: 120px;
	}
}

/* Portrait */
@media screen 
  and (device-width: 375px) 
  and (max-height: 812px) 
  and (orientation: portrait) {
	#section-2 {
		min-height: auto;
		background-size: 135%;
		background-position: 10% 0px;
	}
	#section-2 .detail-wrap {
		min-height: auto;
	}
	.detail {
		height: auto;
		margin-top: 175px;
		padding-top: 90px;
	}
}

/* Landscape */
@media screen 
  and (device-width: 568px) 
  and (device-height: 320px) 
  and (orientation: landscape) {
	#section-2 {
		background-size: 125%;
		background-position: 10% 0px;
	}
	.detail {
		height: auto;
		margin-top: 240px;
		padding: 100px 120px 50px;
	}
}

/* Portrait */
@media screen 
  and (device-width: 320px) 
  and (device-height: 568px) 
  and (orientation: portrait) {
	#section-2 {
		background-size: 135%;
		background-position: 10% 0px;
	}
	.detail {
		height: auto;
		margin-top: 150px;
		padding-top: 90px;
	}
}

/* Landscape */
@media screen 
  and (max-width: 660px) 
  and (max-height: 280px) 
  and (orientation: landscape) {
	#section-2 {
		background-size: 115%;
		background-position: top;
	}
	.detail {
		height: auto;
		margin-top: 220px;
		padding: 100px 130px 70px;
	}
}

/* Portrait */
@media screen 
  and (max-width: 280px) 
  and (max-height: 660px) 
  and (orientation: portrait) {
	#section-2 {
		background-size: 125%;
		background-position: 5% 0px;
	}
	.detail {
		height: auto;
		margin-top: 0;
		padding: 85px 30px 50px;
	}
}

@media (min-width: 1150px) {
	.profile-wrap {
		background-size: 100%;
		background-position: inherit;
	}
}